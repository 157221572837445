import { combineReducers } from "redux";
import { landing } from "./landing.reducer";

const appReducers = combineReducers({
  landing,
});

const rootReducer = (state, action) => {
  return appReducers(state, action);
};

export default rootReducer;
