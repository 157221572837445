import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import FooterLogo from "../../utils/images/footer-logo.svg";
import FacebookIcon from "../../utils/images/facebook-icon.svg";
import InstagramIcon from "../../utils/images/instagram-icon.svg";
import tiktokblack from "../../utils/images/tiktok-black.svg";
import callicon from "../../utils/images/call-icon.svg";
import mailicon from "../../utils/images/mail-icon.svg";



function Footer(props) {

    return (
        <app-footer>
            <div className="footer">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3">
                            <div className="footer-logo">
                                <a href="/"><img src={FooterLogo} alt="" /></a>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-text">

                                <ul>
                                    <h3>Get in touch</h3>
                                    <li>
                                        <a href="tel:0330 113 2965">
                                            <img src={callicon} />
                                            0330 113 2965
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:hello@sumoeats.co.uk">
                                            <img src={mailicon} />
                                            hello@sumoeats.co.uk
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-text">

                                <ul>
                                    <h3>Are you a merchant?</h3>
                                    <li>
                                        <a href="/merchants">
                                         Join the waitlist
                                        </a>
                                    </li>
                                  
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-text">
                                <ul>
                                    <h3>Work with us</h3>
                                    <li>Influencers</li>
                                    <li>Bloggers</li>
                                    <li>Agencies</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-icon">
                                <ul>
                                    <li><a href="https://www.tiktok.com/@sumoeats" target="_blank"><img src={tiktokblack} alt="" /></a></li>
                                    <li><a href="https://www.facebook.com/profile.php?id=61560203094731&mibextid=LQQJ4d" target="_blank"><img src={FacebookIcon} alt="" /></a></li>
                                    <li><a href="https://www.instagram.com/sumoeats_/" target="_blank"><img src={InstagramIcon} alt="" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-footer>
    )

};



export default Footer;