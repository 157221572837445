export const authConstants = {

    REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
    REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',

    REGISTER_MERCHANT_REQUEST: 'REGISTER_MERCHANT_REQUEST',
    REGISTER_MERCHANT_SUCCESS: 'REGISTER_MERCHANT_SUCCESS',
    REGISTER_MERCHANT_FAILURE: 'REGISTER_MERCHANT_FAILURE',


    USER_PREFERENCE_REQUEST: 'USER_PREFERENCE_REQUEST',
    USER_PREFERENCE_SUCCESS: 'USER_PREFERENCE_SUCCESS',
    USER_PREFERENCE_FAILURE: 'USER_PREFERENCE_FAILURE',

    MERCHANT_PREFERENCE_REQUEST: 'MERCHANT_PREFERENCE_REQUEST',
    MERCHANT_PREFERENCE_SUCCESS: 'MERCHANT_PREFERENCE_SUCCESS',
    MERCHANT_PREFERENCE_FAILURE: 'MERCHANT_PREFERENCE_FAILURE',


    TOTAL_USER_REQUEST: 'TOTAL_USER_REQUEST',
    TOTAL_USER_SUCCESS: 'TOTAL_USER_SUCCESS',
    TOTAL_USER_FAILURE: 'TOTAL_USER_FAILURE',

    TOTAL_MERCHANT_REQUEST: 'TOTAL_MERCHANT_REQUEST',
    TOTAL_MERCHANT_SUCCESS: 'TOTAL_MERCHANT_SUCCESS',
    TOTAL_MERCHANT_FAILURE: 'TOTAL_MERCHANT_FAILURE',
    

    USER_LOGOUT: 'USER_LOGOUT',

}