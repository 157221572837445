import React from "react";
import { Bars } from "react-loader-spinner";

function CustomLoader() {
  return (
    <Bars
      height="80"
      width="80"
      color="#960012"
      ariaLabel="bars-loading"
      wrapperStyle={{ justifycontent: 'center', position: 'fixed', Zindex: 99, background: '#fff', width: '100%', bottom: 0, top: 0, }}
      wrapperClass="loader"
      visible={true}
    />
  );
}

export default CustomLoader;
