import React from "react";
const Home = React.lazy(() => import("../pages/landingUser"));
const Questionnaire = React.lazy(() =>
  import("../pages/landingUser/questionnaire")
);
const QuestionnaireTabTakeaway = React.lazy(() =>
  import("../pages/landingMerchant/questionnaire-tab-takeaway")
);
const LandingMerchant = React.lazy(() => import("../pages/landingMerchant"));
const LandingUserThankyou = React.lazy(() => import("../pages/landingUser/thankyou"));
const ThankYouLandingMerchant =React.lazy(() => import("../pages/landingMerchant/thankyou"));
// import Mysumo from "./pages/my-sumo";
// import Landing from "./pages/home/landing";
// import Landingtakeaway from "./pages/home/landingtakeaway";
// import Home1 from "./pages/home1";
// import Signup from "./pages/signup";
// import Signin from "./pages/signin";
// import Upload from "./pages/components/upload";
// import Varification from "./pages/components/varification";
// import DealsDetails from "./pages/components/deals-details";
// import Account from "./pages/account";
// import Delivery from "./pages/account/delivery";
// import Payment from "./pages/account/payment";
// import Order from "./utils/commonFunctions/order";
// import Payments from "./utils/commonFunctions/order/payments";
// import Confirm from "./utils/commonFunctions/order/confirm";
// import Confirm1 from "./utils/commonFunctions/order/confirm1";
// import Marketing from "./pages/marketing";

// import Questlonnalretab from "./pages/home/questlonnalre-tab";

const routes = [
  {
    path: "/join",
    name: "Home",
    title: "Home",
    element: <Home />,
    exact: true,
  },
  {
    path: "/questionnaire",
    name: "Questionnaire",
    title: "Questionnaire",
    element: <Questionnaire />,
  },
  {
    path: "landing/merchant/questionnaire",
    name: "Home",
    title: "Home",
    element: <QuestionnaireTabTakeaway />,
  },
  {
    path: "/merchants",
    name: "Home",
    title: "Home",
    element: <LandingMerchant />,
  },
  {
    path: "/thankyou",
    name: "Home",
    title: "Home",
    element: <LandingUserThankyou />,
  },
  {
    path: "/merchants/thankyou",
    name: "Home",
    title: "Home",
    element: <ThankYouLandingMerchant />,
  },
  {
    path: "/account",
    name: "Home",
    title: "Home",
    element: <Home />,
  },
];

export default routes;
