import { authConstants, status } from "../_constants";

const initialState = {};

export function landing(state = initialState, action) {
  switch (action.type) {

    //   User registration reducers
    case authConstants.REGISTER_USER_REQUEST:
      return {
        ...state,
        get_registration_status: status.IN_PROGRESS,
        get_registration_data: action.data,
      };
    case authConstants.REGISTER_USER_SUCCESS:
      return {
        ...state,
        get_registration_status: status.SUCCESS,
        get_registration_data: action.data,
      };
    case authConstants.REGISTER_USER_FAILURE:
      return {
        ...state,
        get_registration_status: status.FAILURE,
        get_registration_data: action.data,
      };

      //   User preference reducers
    case authConstants.USER_PREFERENCE_REQUEST:
      return {
        ...state,
        get_users_preference_status: status.IN_PROGRESS,
        get_users_preference_data: action.data,
      };
    case authConstants.USER_PREFERENCE_SUCCESS:
      return {
        ...state,
        get_users_preference_status: status.SUCCESS,
        get_users_preference_data: action.data,
      };
    case authConstants.USER_PREFERENCE_FAILURE:
      return {
        ...state,
        get_users_preference_status: status.FAILURE,
        get_users_preference_data: action.data,
      };

      //   Total User reducers
    case authConstants.TOTAL_USER_REQUEST:
      return {
        ...state,
        get_users_total_status: status.IN_PROGRESS,
        get_users_total_data: action.data,
      };
    case authConstants.TOTAL_USER_SUCCESS:
      return {
        ...state,
        get_users_total_status: status.SUCCESS,
        get_users_total_data: action.data,
      };
    case authConstants.TOTAL_USER_FAILURE:
      return {
        ...state,
        get_users_total_status: status.FAILURE,
        get_users_total_data: action.data,
      };

      
    //   Merchant registration reducers
    case authConstants.REGISTER_MERCHANT_REQUEST:
      return {
        ...state,
        get_merchant_status: status.IN_PROGRESS,
        get_merchant_data: action.data,
      };
    case authConstants.REGISTER_MERCHANT_SUCCESS:
      return {
        ...state,
        get_merchant_status: status.SUCCESS,
        get_merchant_data: action.data,
      };
    case authConstants.REGISTER_MERCHANT_FAILURE:
      return {
        ...state,
        get_merchant_status: status.FAILURE,
        get_merchant_data: action.data,
      };

      //   Merchant preference reducers
    case authConstants.MERCHANT_PREFERENCE_REQUEST:
      return {
        ...state,
        get_merchant_preference_status: status.IN_PROGRESS,
        get_merchant_preference_data: action.data,
      };
    case authConstants.MERCHANT_PREFERENCE_SUCCESS:
      return {
        ...state,
        get_merchant_preference_status: status.SUCCESS,
        get_merchant_preference_data: action.data,
      };
    case authConstants.MERCHANT_PREFERENCE_FAILURE:
      return {
        ...state,
        get_merchant_preference_status: status.FAILURE,
        get_merchant_preference_data: action.data,
      };


      //   Total Merchant reducers
    case authConstants.TOTAL_MERCHANT_REQUEST:
      return {
        ...state,
        get_merchant_total_status: status.IN_PROGRESS,
        get_merchant_total_data: action.data,
      };
    case authConstants.TOTAL_MERCHANT_SUCCESS:
      return {
        ...state,
        get_merchant_total_status: status.SUCCESS,
        get_merchant_total_data: action.data,
      };
    case authConstants.TOTAL_MERCHANT_FAILURE:
      return {
        ...state,
        get_merchant_total_status: status.FAILURE,
        get_merchant_total_data: action.data,
      };

    default:
      return state;
  }
}
