import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import routes from "./routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./utils/style/style.scss";
import { Provider } from "react-redux";
import { store } from "./_store";
import CustomLoader from "./utils/commonFunctions/commonLoader";
// import MainLayout from './components/layouts/MainLayout'
// import { ToastContainer } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";


function App() {
  // const navigate = useNavigate();
  const createRoutes = () => {
    const retData = routes.map((route, index) => {
      return route.element ? (
        <Route
          path={route.path}
          element={route.element}
          exact={route.exact}
          key={`page-route-${index}`}
        />
      ) : null;
    });
    return retData;
  };

  return (
    <Provider store={store}>
      <div className="App">
        <Header />
        {/* <MainLayout> */}
          <Suspense fallback={CustomLoader()}>
            <Routes>
              <Route
                path="/"
                exact
                element={<Navigate to="/join" replace />}
              />
              {createRoutes()}
            </Routes>
            <ToastContainer
              enableMultiContainer
              containerId={"TOP_RIGHT"}
              hideProgressBar={true}
            />
          </Suspense>
          <Footer />
        {/* </MainLayout> */}
      </div>
    </Provider>
  );
}

export default App;
