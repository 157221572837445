import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
// import { NavLink } from "react-router-dom";
import HeaderLogo from "../../utils/images/header-logo.svg";
// import SearchIcon from "../../utils/images/search-icon.svg";
// import MenuIcon from "../../utils/images/menu-icon.svg";


function Header(props) {

    return (
        <app-header>
            <header className="header-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="header-left">
                                <div className="logo">
                                    <a href="/"><img src={HeaderLogo} alt="" /></a>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-8">
                                <div className="header-right">
                                    <div className="search-icon">
                                        <img src={SearchIcon} alt="" />
                                    </div>
                                    <ul>
                                        <li><NavLink to="/Signup" className="active">Sign Up</NavLink></li>
                                        <li>
                                            <NavLink to="/Signin" className="primary-btn" title="Log In">Log In</NavLink>
                                        </li>
                                    </ul>
                                    <img src={MenuIcon} alt="" className="menu-icon" />
                                </div>
                            </div> */}
                    </div>
                </div>
            </header>
        </app-header>
    )
};



export default Header;