import { createStore, applyMiddleware, compose } from "redux";
import {thunk} from "redux-thunk";
// import { createLogger } from "redux-logger";
import rootReducer from "../_reducers";
// import { configureStore } from "@reduxjs/toolkit";

// const loogerMiddleware = createLogger();
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,applyMiddleware(thunk)
  // composeEnhancer(applyMiddleware(
  //   thunkMiddleware,
  //   loogerMiddleware
  // ))
);
